import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Grid, Row, Col } from 'react-styled-flexboxgrid'

import { Wrapper } from '../../../atoms'

const HeaderHome = ({ ceLogo }) => {
  return (
    <header>
      <Wrapper pv={'xxlarge'} >
        <Grid>
          <Row>
            <Col xs={12}>
              <Wrapper dFlex justifyContent={'center'}>
                <GatsbyImage image={ceLogo.gatsbyImageData} alt={ceLogo.alt || ''} draggable={false} />
              </Wrapper>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    </header>
  )
}

export default HeaderHome
