import * as React from "react"
import { graphql } from 'gatsby'
import { Grid, Row } from 'react-styled-flexboxgrid'

import { Layout, Seo } from '../components/commons'
import { HeaderHome, ButtonHome } from '../components/home'

const IndexPage = ({ data }) => {
  const { datoCmsHome } = data;

  return (
    <Layout>
      <Seo content={datoCmsHome.seo} />
      <HeaderHome ceLogo={datoCmsHome.ceLogo} />
      <Grid>
        <Row>
          {datoCmsHome.cards.map(card => (
            <ButtonHome key={card.id} content={card} />
          ))}
        </Row>
      </Grid>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    datoCmsHome {
      ceLogo {
        alt
        url
        gatsbyImageData(
          width: 160
          placeholder: NONE
        )
      }
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      cards {
        id
        backgroundColor {
          hex
        }
        backgroundImage {
          alt
          url
        }
        logo {
          alt
          url
          gatsbyImageData(
            width: 200
            placeholder: NONE
          )
        }
        logoHover {
          alt
          url
          gatsbyImageData(
            width: 200
            placeholder: NONE
          )
        }
        name
        disabled
        link {
          slug
          rally {
            slug
          }
        }
      }
    }
  }
`
