import React from 'react';
import styled, { css } from 'styled-components'
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Col } from 'react-styled-flexboxgrid'
import { Wrapper, Text } from '../../../atoms'

const ButtonHome = ({ content }) => (
  <Col xs={12} lg={6}>
    <Wrapper mv={'regular'}>
      <ButtonHomeLink to={content.link ? `${content.link?.rally.slug}/${content.link?.slug}` : '/'}>
        <ButtonHomeWrapper dFlex justifyContent={'center'} alignItems={'center'} r={'big'} bgImage={content.backgroundImage?.url}>
          {content.disabled &&
            <ButtonHomeComingSoon>
              <Text font={'Butler'} size={'xlarge'} color={'ceWhite'} opacity={0.5} weight={'bold'}>Coming soon</Text>
            </ButtonHomeComingSoon>
          }
          <GatsbyImage image={content.logoHover.gatsbyImageData} alt={content.logoHover.alt || content.name} draggable={false} />
          <MaskWrapper dFlex justifyContent={'center'} alignItems={'center'} color={content.backgroundColor.hex}>
            <GatsbyImage image={content.logo.gatsbyImageData} alt={content.logo.alt || content.name} draggable={false}  />
          </MaskWrapper>
        </ButtonHomeWrapper>
      </ButtonHomeLink>
    </Wrapper>
  </Col>
);

const ButtonHomeLink = styled(Link)`
  display: block;
`

const ButtonHomeWrapper = styled(Wrapper)`
  width: 100%;
  height: 400px;
  overflow: hidden;
  
  ${({ bgImage}) => bgImage && css`
    background-image: url(${bgImage});
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  `}
`;

const MaskWrapper = styled(Wrapper)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  ${ButtonHomeWrapper}:hover & {
    opacity: 0;
  }
`;

const ButtonHomeComingSoon = styled(Wrapper)`
  position: absolute;
  bottom: 2.5rem;
  z-index: 6;
  margin-bottom: 0;
  font-size: 4rem;
  color: ${({theme}) => theme.colors.white};
`;

export default ButtonHome;
